import React, { useEffect, useState } from "react";
import "../assets/css/LandingPage.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import botvid from "../assets/videos/New folder/landing page chatbot.gif";
import LazyLoad from "react-lazy-load";
import IntegratedWith from "./IntegratedWith";
import { useNavigate } from "react-router-dom";
import { jwtDecode as jwt_decode } from "jwt-decode";
import axiosInstance from "../utils/axiosInstance";
import { useAuth } from "../Authcontext";
import indiaVector from "../assets/images/landingpage/indiaVector.svg";
import { color } from "framer-motion";
import SupportrdWith from "./SupportedWith";
import { FaTwitter } from "react-icons/fa";
import nvidia from "../assets/images/landingpage/nvidia.svg";
import microsoft from "../assets/images/landingpage/microsoft.svg";
import aws from "../assets/images/landingpage/aws.svg";

const LandingPage = () => {
  const [headline, setHeadline] = useState("");
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [isHovered, setIsHovered] = useState(false);

  // Window Width Used to show Slider or Not.
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  // Window Width Used to show Slider or Not.

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const video = document.createElement("video");
    video.src = botvid;
    video.preload = "auto"; // Preload the entire video
    video.onloadedmetadata = () => {
      console.log("Video metadata loaded");
      // Optionally handle other setup or state updates here
    };
  }, []);

  useEffect(() => {
    document.querySelector(".full-height").classList.add("loaded");
    const textToType =
      "Empowering Legal Professionals with AI-Powered Tools. Get instant legal insights, track your cases in real-time, and streamline your workflow with our easy-to-use platform.";
    let currentText = "";
    const typeHeadlineText = () => {
      currentText = textToType.substring(0, currentText.length + 1);
      setHeadline(currentText);

      if (currentText !== textToType) {
        setTimeout(typeHeadlineText, 25);
      }
    };

    typeHeadlineText();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      /* global google */
      const initializeGoogleSignIn = () => {
        google.accounts.id.initialize({
          client_id:
            "246105724090-95arttgdnp0sohiaqqtvpctr875kassq.apps.googleusercontent.com",
          callback: googleSuccess,
        });
        google.accounts.id.prompt(); // Display the One Tap prompt
      };

      const googleSuccess = (response) => {
        const credentialResponseDecode = jwt_decode(response.credential);
        const id_token = response.credential;
        // Using axios for sending data to the server
        axiosInstance
          .post(
            "api/google-login/",
            { id_token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("Server response:", response);
            sessionStorage.setItem("user-info", JSON.stringify(response.data));
            sessionStorage.setItem("access_token", response.data.access); // Make sure your backend is sending this data
            // Call login function if you have one, or handle the login state here
            login();
            navigate("/");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      // Load the Google Identity Services library and initialize it
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [navigate, isAuthenticated, login]);

  const handleButtonClick = (path) => {
    if (!isAuthenticated) {
      sessionStorage.setItem("intendedURL", path);
      navigate("/signup");
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <div className="full-height with-background">
        <div className="landing-container">
          <div
            className="Landig"
            style={{
              backgroundImage: `url(${"https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/background.png"})`,
              backgroundSize: "conver",
              backgroundPosition: "0 100px",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div style={{ textAlign: "center", marginTop: "21px" }}>
              <h1 className="headline">
                <span className="heading-text-1" style={{ color: "#5D54F1" }}>
                  Case
                </span>
                <span
                  className="headline heading-text-2"
                  data-aos="fade-up"
                  data-aos-duration="5000"
                  data-aos-delay="200"
                >
                  <span style={{ color: "#000" }}>Minister</span>
                </span>
              </h1>
            </div>
            <div className="two-para">
              <p
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "21px",
                  height: "70px",
                }}
              >
                {headline}
              </p>
              {email && <p>Welcome, {email}</p>}
            </div>
            <div className="two-button">
              <button
                className="landing-button"
                onClick={() => handleButtonClick("/talkaboutcase")}
              >
                <div className="text-box">
                  <span style={{ fontWeight: "bold" }}>
                    USE OUR CHATBOT FOR FREE
                  </span>
                </div>
              </button>
              <button
                className="landing-button"
                onClick={() => handleButtonClick("/mycases")}
              >
                <div className="text-box">
                  <span style={{ fontWeight: "bold" }}>
                    UNLOCK LIVE CASE TRACKING
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="roboimg" style={{ position: "relative" }}>
            <div
              className="main-img-container"
              style={{ position: "relative" }}
            >
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/main homepage vector.svg"
                  alt="Background image"
                  className="landingpage-background-image"
                />
              </LazyLoad>
            </div>
            <div className="gif-container">
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/landing page chatbot.gif"
                  alt="Animated robot gif"
                  className="overlay-image"
                />
              </LazyLoad>
            </div>
            <div className="top-container">
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/robot.png"
                  alt="New top"
                  className="landing-top-image"
                />
              </LazyLoad>
            </div>
            <LazyLoad>
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/circles bg right.svg"
                alt="Description of the bottom"
                className="headline-image"
              />
            </LazyLoad>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        {width <= 700 ? (
          <IntegratedWith />
        ) : (
          <div className="recog-img-container">
            <img src={nvidia} alt="nvidia logo" />
            <img src={microsoft} alt="microsoft logo" />
            {/* <img
              src={aws}
              alt="aws logo"
              width={100}
              style={{ paddingLeft: "15px" }}
            /> */}
          </div>
        )}
        <div className="text-content">
          <p className="fade-in-text" data-ios="true">
            We have been recognised by the renowned{" "}
            <span style={{ color: "#5D54F1" }}>
              NVIDIA Inception Program and Microsoft For Startups{" "}
            </span>{" "}
            {/* and <span style={{ color: "#5D54F1" }}>Amazon Web Services,</span>{" "} */}
            validating our innovative approach and growth potential. These
            endorsements highlight our commitment to{" "}
            <span style={{ color: "#5D54F1" }}>
              excellence and industry leadership.
            </span>
          </p>
        </div>
      </div>
      <div className="follow-up-screen" style={{ position: "relative" }}>
        <div className="paras">
          <div className="first-image">
            <LazyLoad>
              <div>
                <img
                  className="main-image1"
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/indiaVector.svg"
                  alt="Descriptive text for the first image"
                />
                {/* <img className="main-image1-mobile" src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/mobile-hand-vector.png" alt="Descriptive text for the mobile image" /> */}
              </div>
            </LazyLoad>
            <div className="overlay-text">
              <div className="fade-in-line">
                <h3>
                  <span style={{ color: "#5d54f1" }}>
                    Unprecedented <br /> Legal{" "}
                  </span>
                  <span style={{ color: "black" }}>
                    Institution <br /> Support{" "}
                  </span>
                </h3>
                <p>
                  Official Recognition of CaseMinister's potential to transform
                  legal practices​, covering multiple jurisdictions across India
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bar-slick">
          <SupportrdWith />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
