import BusinessStandard from "../assets/images/InTheNews/BusinessStandard.png";
import ThePrint from "../assets/images/InTheNews/ThePrint.png";
import ANINews from "../assets/images/InTheNews/ANINews.png";
import "../assets/css/InTheNews.css";

const newsData = [
  {
    id: 1,
    title: "Business Standard",
    url: "https://www.business-standard.com/content/press-releases-ani/caseminister-transforming-the-indian-legal-system-with-artificial-intelligence-124091100019_1.html",
    logo: BusinessStandard,
  },
  {
    id: 2,
    title: "ANI News",
    url: "https://www.aninews.in/news/business/caseminister-transforming-the-indian-legal-system-with-artificial-intelligence20240910141354/",
    logo: ANINews,
  },
  {
    id: 3,
    title: "The Print",
    url: "https://theprint.in/ani-press-releases/caseminister-transforming-the-indian-legal-system-with-artificial-intelligence/2260817/",
    logo: ThePrint,
  },
];

const InTheNews = () => {
  return (
    <div className="ITN-container">
      <div className="ITN-heading-container">
        <h4 className="ITN-heading">In the news</h4>
      </div>
      <div className="ITN-news-container">
        {newsData.map((news) => (
          <a href={news.url} target="_blank" rel="noreferrer" key={news.id}>
            <img className="ITN-news" src={news.logo} alt={news.title} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default InTheNews;
